<template>
  <form-builder :schema="schema" />
</template>

<script>
export default {
  name: 'SettingsTab',
  emits: ['change', 'deactivate', 'submit'],
  props: {
    type: {
      type: String,
      default () {
        return 'picking'
      }
    },
    queue: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      model: {
        ...this.queue
      }
    }
  },
  computed: {
    adapters () {
      const types = {
        picking: [
          { id: 'routed_batch', name: this.$t('Routed Batch') },
          { id: 'routed_discrete', name: this.$t('Routed Discrete') },
          { id: 'custom', name: this.$t('Custom') }
        ],
        packing: [
          { id: 'parallelAssembling', name: this.$t('Parallel Assembling') },
          { id: 'wholesale', name: this.$t('Wholesale') },
          { id: 'discrete_assembling', name: this.$t('Discrete Assembling') },
          { id: 'returning', name: this.$t('Returning') },
          { id: 'bundling', name: this.$t('Bundling') }
        ],
        'routed_batch': [
          { id: 'routed_batch', name: this.$t('Routed Batch') }
        ],
        'routed_discrete': [
          { id: 'routed_discrete', name: this.$t('Routed Discrete') }
        ],
        'discrete_assembling': [
          { id: 'discrete_assembling', name: this.$t('Routed Discrete') }
        ],
        revision: [
          { id: 'revision', name: this.$t('Revision')}
        ],
        allocation: [
          { id: 'allocation', name: this.$t('Allocation') }
        ],
        measuring: [
          { id: 'measuring', name: this.$t('Measuring') }
        ],
        pallets: [
          { id: 'pallets', name: this.$t('Pallets') }
        ],
        returning: [
          { id: 'returning', name: this.$t('Returning') }
        ],
        totes: [
          { id: 'totes', name: this.$t('Totes') }
        ],
        bundling: [
          { id: 'bundling', name: this.$t('Bundling') }
        ],
        sorting: [
          { id: 'sorting', name: this.$t('Sorting') },
          { id: 'advanced_sorting', name: this.$t('Advanced Sorting') }
        ]
      }

      return types[this.type] || [{ id: 'sorting', name: this.$t('Sorting') }]
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                field: 'name',
                value: this.model.name,
                required: true,
                label: this.$t('Name'),
                wrapperStyleClasses: this.adapters.length > 1
                  ? 'col-12 col-md-6 q-pa-xs'
                  : 'col-12 q-pa-xs',
                onChange: (name) => {
                  this.$emit('change', { ...this.model, name })
                }
              },
              {
                if: this.adapters.length > 1,
                type: 'select',
                label: this.$t('Adapter'),
                field: 'adapter',
                value: this.model.adapter,
                disabled: !!this.model.id,
                options: this.adapters,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                onChange: (adapter) => {
                  this.$emit('change', { ...this.model, adapter: adapter.id })
                }
              },
              {
                if: this.type !== 'preprocessing',
                type: 'multiselect',
                label: this.$t('Warehouse'),
                field: 'warehouse',
                value: this.model._embedded.warehouse,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                disabled: !!this.model.id,
                required: this.type !== 'preprocessing'? true : false,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return `${row.name || this.$t('No name')} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' },
                      { type: 'in', field: 'type', values: ['client', 'fulfillment'] }
                    ]
                  }

                  return this.$service.warehouse.getAll(query)
                },
                onChange: warehouse => {
                  this.$emit('change', { ...this.model, _embedded: { ...this.model._embedded, warehouse } })
                }
              },
              {
                if:this.type === 'preprocessing',
                type: 'input',
                inputType: 'text',
                field: 'priority',
                value: this.model.priority,
                required: true,
                label: this.$t('Priority'),
                wrapperStyleClasses: this.adapters.length > 1
                    ? 'col-12 col-md-6 q-pa-xs'
                    : 'col-12 q-pa-xs',
                onChange: (priority) => {
                  console.log(this.model)
                  this.$emit('change', { ...this.model, priority })
                }
              },
              {
                if:this.type === 'preprocessing',
                type: 'multiselect',
                label: this.$t('Sender'),
                wrapperStyleClasses: this.adapters.length > 1
                    ? 'col-12 col-md-6 q-pa-xs'
                    : 'col-12 q-pa-xs',
                field: '_embedded.deliveryRequest.sender',
                value: this.model.sender? this.model.sender: {},
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    if (row.name) {
                      return `${row.name} (${row.id})`
                    } else {
                      return ` `
                    }
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.sender.getAll(query)
                },
                onChange: (sender) => {
                  this.$emit('change', { ...this.model, sender })
                },
                btnStyleClasses: 'q-pl-sm'
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    queue (value) {
      this.model = { ...value }
    }
  },
  mounted () {
    this.model = { ...this.queue }

    if (this.adapters.length === 1) {
      this.model.adapter = this.adapters[0].id
    }
  },
  methods: {
    handleDeactivate () {
      this.$emit('deactivate')
    },
    save () {
      this.$emit('submit')
    }
  }
}
</script>
