<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 40vw;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          <q-icon name="settings" />

          {{ $t('Set the queue settings') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center q-pt-none">
        <q-carousel
          :model-value="slide"
          transition-prev="slide-right"
          transition-next="slide-left"
          height="auto"
          animated
        >
          <q-carousel-slide name="type">
            <div class="row">
              <div class="col q-pa-sm">
                <q-card
                  style="min-height: 100px;"
                  class="row items-center justify-center clickable"
                  @click="setDefaultSettings(false)"
                >
                  <q-card-section class="text-h6 text-center">
                    {{ $t('Picking by wave') }}
                  </q-card-section>
                </q-card>
              </div>

              <div class="col q-pa-sm">
                <q-card
                  style="min-height: 100px;"
                  class="row items-center justify-center clickable"
                  @click="setDefaultSettings(true)"
                >
                  <q-card-section class="text-h6 text-center">
                    {{ $t('Picking by order') }}
                  </q-card-section>
                </q-card>
              </div>
            </div>
          </q-carousel-slide>

          <q-carousel-slide name="settings">
            <queue-settings
              :settings="settings"
              :queue="queue"
              @change="updateQueue"
            />
          </q-carousel-slide>
        </q-carousel>
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          color="light-blue-9"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Save')"
          @click="save"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Close')"
          @click="close"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>

// Vuex
import { mapActions} from 'vuex'

// Components
import QueueSettings from '../queue/QueueSettings.vue'

// Lodash
import _ from 'lodash'

const defaultSettings = {
  'picking-endpoint-place-type': 'distribution',
  'wave-fill-type': 'automatic',
  'wave-state': 'preparing',
  'wave-order-limit': 1
}

Object.freeze(defaultSettings)

export default {
  name: 'QueueSettingsModal',
  emits: ['submit'],
  components: {
    QueueSettings
  },
  data () {
    return {
      isOpen: false,
      service: null,
      queue: null,
      settings: [],
      options: {},
      slide: 'type',
      shopFilterRow: null,
      ifShop: false
    }
  },
  methods: {
    ...mapActions([
      'loadPickingQueueRawSettings',
      'loadAssemblingQueueRawSettings'
    ]),
    updateQueue (model) {
      this.queue = model
    },
    close () {
      this.isOpen = false
    },
    open (queue, service, options = {}) {
      this.options = options
      this.slide = options.waveSettings && options.isNew
        ? 'type'
        : 'settings'
      this.queue = _.cloneDeep(queue)
      service.getSettings(this.queue.id, this.queue.adapter)
        .then(settings => {
          this.settings = settings.filter(({ model }) => model !== 'settings.wave-state')
          if(this.options.type === 'picking') {
            this.loadPickingQueueRawSettings(queue.id)
              .then(item => {
                this.shopFilterRow = []
                if (Array.isArray(item.filters)) {
                  this.shopFilterRow = item.filters.filter(e=>e.field = 'shop')
                  if(this.shopFilterRow.length > 0) {
                    if (this.shopFilterRow.some(e=>e.type ==='neq' || e.type === 'notin')) {
                      this.ifShop = true
                    }
                  }
                }
              })
          } else if (this.options.type === 'packing') {
            this.loadAssemblingQueueRawSettings(queue.id)
              .then(item => {
                this.shopFilterRow = []
                if (Array.isArray(item.filters)) {
                  this.shopFilterRow = item.filters.filter(e=>e.field = 'shop')
                  if(this.shopFilterRow.length > 0) {
                    if (this.shopFilterRow.some(e=>e.type ==='neq' || e.type === 'notin')) {
                      this.ifShop = true
                    }
                  }
                }
              })
          }
        })

      this.service = service
      this.isOpen = true
    },
    setDefaultSettings (isOk) {
      if (!isOk) {
        this.slide = 'settings'
        return
      }

      this.queue.settings = {
        ...this.queue.settings,
        ...defaultSettings
      }

      return this.save()
    },
    save () {
      return this.service.saveFilters(this.queue.settings, this.queue.id , this.ifShop)
        .then(queue => {
          this.$emit('submit', queue)
        })
    }
  }
}
</script>
