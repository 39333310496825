<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 40vw; overflow: visible;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('New Queue') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <settings-tab
          :queue="queue"
          :type="type"
          @change="updateQueue"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          color="dark"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Close')"
          @click="close"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Save')"
          @click="save"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapMutations } from 'vuex'

// Components
import SettingsTab from '../queue/SettingsTab.vue'

function getNewQueue () {
  return {
    name: '',
    adapter: null,
    settings: {
      filters: {}
    },
    _embedded: {
      warehouse: null
    }
  }
}

export default {
  name: 'QueueSettingsModal',
  emits: ['submit'],
  components: {
    SettingsTab
  },
  data () {
    return {
      type: 'picking',
      isOpen: false,
      service: null,
      queue: getNewQueue()
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    updateQueue (model) {
      this.queue = { ...model }
    },
    close () {
      this.isOpen = false
    },
    open (service, type = 'picking') {
      this.type = type
      this.queue = getNewQueue()
      this.service = service
      this.isOpen = true
    },
    save () {
      if (!this.queue._embedded.warehouse && this.type !== 'preprocessing') {
        this.addErrorNotification('Warehouse is not set!')
        return
      }
      let data
      if (this.type !== 'preprocessing') {
        data = {
          ...this.queue,
          _embedded: undefined,
          settings: {
            ...this.queue.settings,
            filters: []
          },
          warehouse: this.queue._embedded.warehouse.id
        }
      } else {
        data = {
          name:this.queue.name,
          priority: this.queue.priority,
          state: this.queue.state,
          sender: this.queue?.sender?.id
        }
      }


      if (this.type === 'picking') {
        data.settings.filters.push({ type: 'in', alias: 'o', field: 'state', values: ['confirmed', 'reserved'] })
      }

      return this.service.save(data)
        .then(queue => {
          this.$emit('submit', queue)
        })
    }
  }
}
</script>
