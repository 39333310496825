<template>
  <form-builder :schema="schema" />
</template>

<script>
export default {
  name: 'FilterTab',
  emits: ['change'],
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    settings: {
      type: Array,
      default () {
        return []
      }
    },
    queue: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      model: { ...this.queue }
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              ...this.settings.map(field => {
                const newField = this.$utils.field.create(this.queue, field)
                newField.disabled = this.disabled

                newField.watch = () => {
                  this.$emit('change', this.model)
                }

                newField.wrapperStyleClasses = 'col-12 q-pa-xs'

                return newField
              })
            ]
          }
        ]
      }
    }
  }
}
</script>
